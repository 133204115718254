export const dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss'; // 2020-01-01T00:00:00
// need add format with timezone
export const dateFormat = 'YYYY-MM-DD';
export const timeFormat = 'HH:mm';

export const oldDateFormat = 'MM.DD.YYYY';

export const localTimeFormat = 'hh:mm a';
export const localDataFormat = 'MMM DD YYYY';
export const localDateTimeFormat = 'MMM DD YYYY hh:mm a';
