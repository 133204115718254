import moment from 'moment';
import { dateFormat, localDataFormat } from '../constants/apiDateFormat';

// eslint-disable-next-line @typescript-eslint/default-param-last
function getFormattedDate(date, format = dateFormat, fromFormat) {
  return moment(date, fromFormat).format(format);
}

function getFormattedToday(format = dateFormat) {
  return getFormattedDate(undefined, format);
}

function getTomorrow() {
  return moment().add(1, 'day');
}

function getDaysAgo(count) {
  return moment().subtract(count, 'day');
}

function isToday(date, format) {
  return moment(date, format).isSame(moment(), 'day');
}

function isTomorrow(date, format) {
  return moment(date, format).isSame(getTomorrow(), 'day');
}

function isInMonth(date, month) {
  return moment(month).isSame(date, 'month');
}

function getMonthLastDayOrToday(date) {
  return isInMonth(date) ? moment() : getMonthLastDay(date);
}

function getMonthLastDay(date) {
  const firstDay = getMonthFirstDay(date);

  const lastDay = firstDay.add(1, 'months').subtract(1, 'days');

  return lastDay;
}

function getMonthFirstDay(date) {
  const firstDay = moment(date).format('MM-YYYY');

  return moment(firstDay, 'MM-YYYY');
}

function isDayAfterOrSame(day, startDate) {
  return isDayAfter(day, startDate) || isSameDay(day, startDate);
}

function isDayAfter(day, startDate) {
  return moment(day).isAfter(startDate);
}

function isDataBefore(day, endDate) {
  return moment(day).isBefore(endDate);
}

function isSameDay(day, comparisonDay) {
  return moment(day).isSame(comparisonDay, 'day');
}

function isFutureOrToday(day, format) {
  return moment().isSameOrBefore(moment(day, format), 'day');
}

function getHumanizeDay(date, format = localDataFormat) {
  const daysToDate = getDaysToDate(date);
  const defaultDayHandler = () => getFormattedDate(date, format);

  return getHumanizeDayByCount(daysToDate, defaultDayHandler);
}

function getDaysToDate(date) {
  if (!date) {
    return null;
  }

  const formattedDate = getFormattedDate(date);
  const formattedNow = getFormattedDate();

  const daysToDate = moment(formattedDate).diff(formattedNow, 'days', false);

  return daysToDate;
}

function getHumanizeDayByCount(daysToDateCount, defaultDayHandler) {
  switch (daysToDateCount) {
    case -1: {
      return 'Yesterday';
    }
    case 0: {
      return 'Today';
    }
    case 1: {
      return 'Tomorrow';
    }
    default: {
      return defaultDayHandler(daysToDateCount);
    }
  }
}

export {
  getFormattedDate,
  getFormattedToday,
  getTomorrow,
  getDaysAgo,
  isToday,
  isTomorrow,
  isInMonth,
  getMonthLastDayOrToday,
  getMonthLastDay,
  getMonthFirstDay,
  isDayAfterOrSame,
  isDayAfter,
  isDataBefore,
  isSameDay,
  isFutureOrToday,
  getHumanizeDay,
  getDaysToDate,
  getHumanizeDayByCount,
};
